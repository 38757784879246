import React, { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'

import './Action.css'

export function Action(props)
{    
    return (
        <div class="div-action-status">
            <button class="button-action" onClick={() => props.onClick()}>{props.Text}</button>
            <div class="text-action">{props.Description}</div>
        </div>
    );
}

