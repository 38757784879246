import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './custom.css';

import { Web3ReactProvider, useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'

import { MetaMask } from './components/Metamask'


function getLibrary(provider) {
    const library = new Web3Provider(provider)
    library.pollingInterval = 12000
    return library
}

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <div className="App">
                <Layout>
                    <Web3ReactProvider getLibrary={getLibrary}>
                        <MetaMask>
                            <Routes>
                            {AppRoutes.map((route, index) => {
                                const { element, ...rest } = route;
                                return <Route key={index} {...rest} element={element} />;
                            })}
                            </Routes>
                        </MetaMask>
                    </Web3ReactProvider>
                </Layout>
            </div>
        );
    }
}
