import React, { Component, useEffect, useContext, useState } from 'react';
import { Web3ReactProvider, useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import { Link, useParams } from 'react-router-dom';
import { ethers } from 'ethers'
import BackEnd from '../BackEnd'

import { NetworkContext } from './Metamask'

import { Action } from './Action'
import { ProgressGrid } from './ProgressGrid'
import { ActionHelper } from './ActionHelper'
import { Loading } from './Loading'

import 'antd/dist/antd.min.css'
import { Space, Spin, notification } from 'antd'

import loginIcon from '../images/icon_login_with_metamask.png';
import backToGameIcon from '../images/icon_tower.png';

export function Login() {
    const { loginId } = useParams()
    const { active, account, library, connector, activate, deactivate } = useWeb3React()    
    const [networkState] = useContext(NetworkContext)

    const [loading, setLoading] = useState(false)
    const [showHelpText, setShowHelpText] = useState(undefined)    

    const initialStates = [
        { id: 0, icon: loginIcon, title: 'Login with MetaMask', helpText:"Complete Signature Request in MetaMask..."},
        { id: 1, icon: backToGameIcon, title: 'Get Back to The Game'},
    ]

    const [states, setStates] = useState(initialStates)
    const [currentStateId, setCurrentStateId] = useState(0)

    const currentState = states.find(s => s.id === currentStateId)

    function setNextState() {
        var nextstateId = currentStateId + 1
        setCurrentStateId(nextstateId)
        setLoading(false)
        setShowHelpText(undefined)
    }

    useEffect(() =>
    {
        if (networkState)
        {
            console.log("Web3React status: ", active, networkState)

            setLoading(true)
            setShowHelpText(undefined)

            BackEnd
                .IsLogined(loginId, account)
                .then(function (response)
                {
                    if (response.ok)
                        setNextState()// setShowResults(true)

                    if (response.status == 409)
                        setShowHelpText("Your wallet is already active in the game. Please use another wallet to login to the game")
                    
                    setLoading(false)                   
                })
                .catch(function (error)
                {
                    console.log("Error", error.message)
                    setLoading(false)
                });
        }
    }, [networkState, account])
   
    async function login()
    {        
        setShowHelpText( currentState.helpText )

        library.getSigner(account)
            .signMessage(loginId)    
            .then((signature) => {                
                let adr = ethers.utils.verifyMessage(loginId, signature)
                console.log(adr)

                setLoading(true)

                BackEnd.Verify(loginId, adr.toString())
                    .then(function (response) {
                        //if (response.ok)
                            setNextState()
                        //setLoading(false)
                    })
                    .catch(function (error) {
                        notification.error({
                            message: "Error",
                            description: error.message,
                        })
                        console.log("Error", error.message)
                        setLoading(false)
                        setShowHelpText(undefined)
                    });
            })
            .catch((error) => {
                notification.error({
                    message: "Error",
                    description: error.message,
                })
                console.log("Error", error.message)

                setLoading(false)
                setShowHelpText(undefined)
            })
    }

  return (
      <div>          
          {(library && account && networkState) && <ProgressGrid states={states} currentStateId={currentStateId}/>}

          {(library && account && networkState && !loading && showHelpText === undefined && currentStateId === 0) && (
              <Action Text="Login" Description="Please login to your wallet" onClick={() => login()}></Action>
          )}         
         
          {(loading) && <Loading text="Please wait..." />}   
          {(showHelpText !== undefined && !loading) && <ActionHelper text={showHelpText} />} 
    </div>
  );
}
