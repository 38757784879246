import { useParams } from 'react-router-dom';
import { ethers } from 'ethers'
import { SBC_Abi } from '../AppConfig'
import BackEnd from '../BackEnd'

import { Web3ActionsSheet } from './Web3ActionsSheet'

import leaveClanIcon from '../images/icon_leave_clan.png'

export function TransferClan() {
    const { wallet, knightId, clanUID, callerKnightUID } = useParams()

    async function getTransaction() {
        return BackEnd.GetTransaction(BackEnd.TransactionType.TransferClan, wallet, knightId, clanUID, callerKnightUID)
    }

    async function addTransaction(transactionHash) {
        return BackEnd.AddTransaction(BackEnd.TransactionType.TransferClan, wallet, transactionHash, knightId, clanUID, callerKnightUID)
    }

    async function callContract(contractAddress, signer) {
        let contract = new ethers.Contract(contractAddress, SBC_Abi, signer)

        return await contract.transferClan(clanUID, knightId, callerKnightUID, { gasLimit: 500000 })
    }

    return (
        <Web3ActionsSheet
            states={[{ icon: leaveClanIcon, title: "Transfer clan" }]}
            getTransaction={getTransaction}
            addTransaction={addTransaction}
            callContract={callContract}
            wallet={wallet}
        />
    )
}