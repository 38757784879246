export default class BackEnd{ 
    
    static CurrentSettings = undefined

    static TransactionType = Object.freeze({
        Burn: "burn",
        ClaimReward: "claimReward",
        Approve: "approve",
        MintKnight: "mintKnight",
        CreateClan: "createClan",
        JoinClan: "joinClan",
        WithdrawJoinClan: "withdrawJoinClan",
        LeaveClan: "leaveClan",
        AbandonClan: "abandonClan",
        ApproveJoinClan: "approveJoinClan",
        DismissJoinClan: "dismissJoinClan",
        SetClanRole: "setClanRole",
        SetClanName: "setClanName",
        ClanStake: "clanStake",
        ClanWithdraw: "clanWithdraw",
        ClanWithdrawRequest: "clanWithdrawRequest",
        KickFromClan: "kickFromClan",
        TransferClan: "transferClan",
        ClaimLootbox: "claimLootbox",
        ClaimBatchLootbox: "claimBatchLootbox",
    })    

    static async GetSettings() {
        if (this.CurrentSettings !== undefined) return this.CurrentSettings

        try {
            const result = await fetch("api/settings")
            this.CurrentSettings = result.json()
            return this.CurrentSettings
        }catch (error){
            console.log("Error", error)
            throw error
        }       
    }

    static async AddTransactionInternal(type, extra, account, hash) {

        let url = "api/transaction/add/" + type + "/" + extra + "/" + account + "/" + hash;
        return await fetch(url);
    }

    static async AddTransaction(type, account, hash, ...restParams) {
        if (restParams.length === 0)
            return await this.AddTransactionInternal(type, "none", account, hash);
        else
            return await this.AddTransactionInternal(type, restParams.join(","), account, hash);      
    }   
    
    static async IsLogined(id, address)
    {     
        let url = "api/logined/" + id + "/" + address
        return await fetch(url);
    }

    static async Verify(id, address) {
        let message = id + "*" + address
        let url = "api/verify/" + message

        return await fetch(url);
    }

    static async GetTransactionInternal(type, extra, account) {
        let url = "api/transaction/get/" + type + "/" + extra + "/" + account

        return await fetch(url)
    }

    static async GetTransaction (type, account, ...restParams) {
        if (restParams.length === 0)
            return await this.GetTransactionInternal(type, "none", account);
        else
            return await this.GetTransactionInternal(type, restParams.join(","), account);
    }

    static async GetLootboxClaimProof(account) {
        let url = "api/lootboxes/getproof/" + account;

        return await fetch(url);
    }

    static async GetLootboxClaimProofBatch(account) {
        let url = "api/lootboxes/getproofbatch/" + account;

        return await fetch(url);
    }
}