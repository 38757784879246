import React, { useEffect, useState } from 'react';
import { useMatch } from "react-router-dom"
import { useWeb3React } from '@web3-react/core'

import { MetamaskHeader } from './MetamaskHeader'
import { Action } from './Action'
import { ActionHelper } from './ActionHelper'

import { injectedProvider } from '../connectors'
import  BackEnd from '../BackEnd'

import { utils, BigNumber } from 'ethers'

import 'antd/dist/antd.min.css'
import { Space, Spin, notification } from 'antd'

export const NetworkContext = React.createContext()

export async function SelectNetwork(library, settings) { 
    try {
        await library.provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: utils.hexValue(settings.chainId) }],
        });
    } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
            await library.provider.request({
                method: 'wallet_addEthereumChain',
                params: [{
                    chainId: utils.hexValue(settings.chainId),
                    chainName: settings.chainName,
                    nativeCurrency: {
                        name: settings.chainNativeCurrencyName,
                        symbol: settings.chainNativeCurrencySymbol,
                        decimals: settings.chainNativeCurrencyDecimals
                    },
                    rpcUrls: [settings.chainRpcUrls],
                    blockExplorerUrls: [settings.chainBlockExplorerUrls]
                }]
            })
        } else throw switchError
    }
}

export async function CheckNetwork(library, settings, chainId) {
    if (settings.chainId != chainId) {
        return await SelectNetwork(library, settings)
    }   
}

export function MetaMask(props) {    
    const { active, account, chainId, library, connector, activate, deactivate } = useWeb3React()
    const [networkState, setNetworkState] = useState(false)   
    const [error, setError] = useState(undefined) 
    const [chainName, setChainName] = useState("") 

    const [balance, setBalance] = useState(undefined)
    const [gasLimitError, setGasLimitError] = useState(undefined)
    const loginPageMatch = useMatch("/login/:id");

    const minGasLimit = 50000

    useEffect(() => { connect() }, [])

    useEffect(() => {
        console.log("connector", connector)
    }, [connector])

    useEffect(() => {
        if (active) {
            setError(undefined)
            setNetworkState(false)

            BackEnd.GetSettings().then((settings) => {
                setChainName(settings.chainName)
                CheckNetwork(library, settings, chainId)
                    .then((result) => {
                        console.log("result", result)

                        library.getBalance(account)
                            .then((weiBalance) => {
                                const needToCheckGas = loginPageMatch == null

                                if (needToCheckGas == false) {
                                    setBalance(weiBalance)
                                    setGasLimitError(false)
                                    setNetworkState(true)

                                    return
                                }

                                library.getGasPrice()
                                    .then((weiGasPrice) => {
                                        setBalance(weiBalance)

                                        const maxCostWei = weiGasPrice * minGasLimit

                                        console.log("Gas max cost in Wei: " + maxCostWei.toString())

                                        //const t1 = formatEther(weiBalance)
                                        //const t2 = utils.parseUnits('0.004', 'ether')
                                        //const t3 = formatEther(maxCostWei)

                                        if (weiBalance < maxCostWei) {
                                            setGasLimitError(true)
                                            return
                                        }

                                        setGasLimitError(false)
                                        setNetworkState(true)
                                    }).catch(error => console.log("Error", error))

                            }).catch(error => console.log("Error", error))
                    })
                    .catch((switchError) => {
                        console.log("Error", switchError)
                        if (switchError.code === 4001)// User rejected the request
                        {
                            setError(switchError.message)
                        }
                        setNetworkState(false)
                    })
            }).catch(error => console.log("Error", error))
        }
    }, [active, chainId, account])

    async function connect() {
        if (typeof window.ethereum !== 'undefined') {
            console.log('MetaMask is installed!')
        } else {
            console.log('MetaMask is not found')
        }
        var status = await activate(injectedProvider)        
        console.log("CheckMetamaskConnection: " + status)
    } 

    return (
        <div>     
            <MetamaskHeader/>

            {(account && !networkState && error === undefined && gasLimitError !== undefined && !gasLimitError)
                && <ActionHelper text={"Please select " + chainName + " network in Metamask"} />}

            {(account && error !== undefined) && <ActionHelper text={error}/>}

            {typeof window.ethereum === 'undefined' ?
                    <Action Text="Download" Description="You need to install MetaMask browser extension to play our game"
                        onClick={() => { window.open("https://metamask.io/download/", '_blank', 'noopener,noreferrer') }}/>
                    : (account == undefined && <Action Text="Connect Metamask" Description="Please connect to Metamask" onClick={() => connect()}/>)}

            {(gasLimitError != undefined && gasLimitError) &&
                <Action Text="Get Gas" Description="You don't seem to have enough gas. Check out our faucet to claim some and reload the page then!"
                    onClick={() => { window.open("https://faucet.stablebattle.io", '_blank', 'noopener,noreferrer') }} />
            }

            <NetworkContext.Provider value={[networkState, setNetworkState]}>                
                {props.children}
            </NetworkContext.Provider>
        </div>
    );
}

