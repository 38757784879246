import React, { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'

import './ActionHelper.css'

import metaMaskLogo from '../images/fox.png'

export function ActionHelper({text})
{    
    return (
        <div class="div-action-status">
            <img src={metaMaskLogo} loading="lazy" alt="" className="foximage"></img>
            <div class="text-action">{text}</div>
        </div>
    )
}

