import React, { Component, useEffect, useState, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Web3ReactProvider, useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import { ethers } from 'ethers'
import { formatEther, formatUnits } from '@ethersproject/units'

import 'antd/dist/antd.min.css'
import { Space, Spin, notification } from 'antd'

import BackEnd from '../BackEnd'
import { ContractAbi, USDC_Abi } from '../AppConfig'
import { NetworkContext } from './Metamask'

import { Action } from './Action'
import { ProgressGrid } from './ProgressGrid'
import { ActionHelper } from './ActionHelper'
import { Loading } from './Loading'

import approveIcon from '../images/icon_approve_token_usage.png'
import mintIcon from '../images/icon_mint_character.png'
import backToGameIcon from '../images/icon_tower.png'

export function Mint() {
    const { wallet, loginId } = useParams()
    const { active, account, library, connector, activate, deactivate } = useWeb3React()
    const [networkState] = useContext(NetworkContext)

    const characterPriceInAAVE = 1000000
    const priceUSDT = characterPriceInAAVE / 1000000

    const Approve = BackEnd.TransactionType.Approve
    const Mint = BackEnd.TransactionType.MintKnight

    const [loading, setLoading] = useState(false)
    const [showHelpText, setShowHelpText] = useState(undefined)

    const initialStates = [
        { id: 0, icon: approveIcon, title: 'Approve Token Usage', helpText: "Complete Request in MetaMask..." },
        { id: 1, icon: mintIcon, title: 'Mint Character', helpText: "Complete Request in MetaMask..."},
        { id: 2, icon: backToGameIcon, title: 'Get Back to The Game' },
    ]

    const [states, setStates] = useState(initialStates)
    const [currentStateId, setCurrentStateId] = useState(-1)

    const isValidationState = currentStateId === -1
    const isApproveState = currentStateId === 0
    const isMintState = currentStateId === 1
    const isResultState = currentStateId === 2

    const currentState = states.find(s => s.id === currentStateId)
    const isWalletValid = account == wallet

    function setNextState() {
        var nextstateId = currentStateId + 1
        setCurrentStateId(nextstateId)
        setLoading(false)
        setShowHelpText(undefined)
    }

    function setMintState() {       
        setCurrentStateId(1)
        setLoading(false)
        setShowHelpText(undefined)
    }

    useEffect(() => {
        if (active && networkState && isValidationState && isWalletValid)
            checkBalance()
    }, [currentStateId, networkState])

    useEffect(() => {
        if (account === undefined)
            return

        if (isWalletValid == false) {
            setShowHelpText("Please connect your wallet in Metamask " + wallet)
            return
        }

        setShowHelpText(undefined)

    }, [account])

    useEffect(() => {
        if (isApproveState === false)
            return

        setLoading(true)

        BackEnd.GetTransaction(Approve, account, priceUSDT)
            .then(function (response) {
                //if (response.ok) setMintState(Mint)
                setLoading(false)
            })
            .catch(function (error) {
                console.log("Error", error.message)
                setLoading(false)
            })
    }, [currentStateId])

    useEffect(() => {
        if (isMintState === false)
            return

        setLoading(true);

        BackEnd.GetTransaction(Mint, account, priceUSDT)
            .then(function (response) {
                //if (response.ok) setShowResults(true)
                setLoading(false)
            })
            .catch(function (error) {
                console.log("Error", error.message)
                setLoading(false)
            })
    }, [currentStateId])  

    async function checkBalance() {
        try {
            setLoading(true)

            const settings = await BackEnd.GetSettings()

            const contractAddress = settings.contractAddress
            const currencyAddress = settings.currencyContractAddress

            const USDTContract = new ethers.Contract(currencyAddress, USDC_Abi, library.getSigner(account))

            const symbol = await USDTContract.symbol()
            const decimals = await USDTContract.decimals()
            const balance = await USDTContract.balanceOf(account)
            const allowance = await USDTContract.allowance(account, contractAddress)            

            const contractBalance = formatUnits(balance, decimals)
            const allowanceBalance = formatUnits(allowance, decimals)

            console.log(contractBalance)
            console.log(allowanceBalance)

            if (contractBalance < priceUSDT) {
                setLoading(false)
                setShowHelpText("You don't seem to have enough USDT to mint a character. Check out our faucet to claim some and reload the page then!")
                return
            }

            if (allowanceBalance >= priceUSDT) {
                setMintState()
                return
            }

            setNextState()
        } catch (error) {
            notification.error({
                message: "Error",
                description: error.message,
            })
            console.log("checkBalance failed:" + error)

            setLoading(false)
        }
    }

    async function approve() {    
        try {           
            setShowHelpText(currentState.helpText)

            var settings = await BackEnd.GetSettings()
            const ContractAddress = settings.contractAddress
            const CurrencyAddress = settings.currencyContractAddress
           
            let approveContract = new ethers.Contract(CurrencyAddress, USDC_Abi, library.getSigner(account))
            
            const transaction = await approveContract.approve(ContractAddress, characterPriceInAAVE, { gasLimit: 500000 })

            if (networkState === false) {
                setShowHelpText("Wrong network in Metamask...")
                return
            }           

            BackEnd.AddTransaction(Approve, account, transaction.hash, priceUSDT)
            console.log(transaction)

            setLoading(true)

            await transaction.wait()  
            
            setLoading(false)
            setNextState()
        } catch (error) {
            notification.error({
                message: "Error",
                description: error.message,
            })
            console.log("Approve failed:" + error)

            setLoading(false)
            setShowHelpText(undefined)
        }
    }

    async function mint() {
        try {
            setShowHelpText(currentState.helpText)

            var settings = await BackEnd.GetSettings()

            const ContractAddress = settings.contractAddress;
            const Pool = settings.pool
            const Coin = settings.coin

            const contract = new ethers.Contract(ContractAddress, ContractAbi, library.getSigner(account))
            const transaction = await contract.mintKnight(Pool, Coin, { gasLimit: 500000 })

            BackEnd.AddTransaction(Mint, account, transaction.hash, priceUSDT)

            if (networkState === false) {
                setShowHelpText("Wrong network in Metamask...")
                return
            }

            console.log(transaction)
            setNextState()
        } catch (error) {
            notification.error({
                message: "Error",
                description: error.message,
            })
            console.log("Mint failed:" + error)

            setLoading(false)
            setShowHelpText(undefined)
        }
    }

    return (
        <div>  
            {(library && account && networkState && currentStateId >= 0) && <ProgressGrid states={states} currentStateId={currentStateId} />}

            {!!(library && account && networkState && !loading && isApproveState && showHelpText === undefined ) &&
                <Action Text="Approve" Description="Please confirm the request in metamask" onClick={() => approve()}></Action>}

            {!!(library && account && networkState && !loading && isMintState && showHelpText === undefined ) &&
                <Action Text="Mint" Description="Please confirm the request in metamask" onClick={() => mint()}></Action>}

            {(loading) && <Loading text="Please wait..." />}
            {(showHelpText !== undefined && !loading) && <ActionHelper text={showHelpText} />} 
    </div>
    );
}
