import { useParams } from 'react-router-dom';
import { ethers } from 'ethers'
import { SBC_Abi } from '../AppConfig'
import BackEnd from '../BackEnd'

import { Web3ActionsSheet } from './Web3ActionsSheet'

import clanStakeIcon from '../images/icon_mint_character.png'

export function ClanStake() {
    const { wallet, clanUID, amount } = useParams()

    async function getTransaction() {
        return BackEnd.GetTransaction(BackEnd.TransactionType.ClanStake, wallet, clanUID, amount)
    }

    async function addTransaction(transactionHash) {
        return BackEnd.AddTransaction(BackEnd.TransactionType.ClanStake, wallet, transactionHash, clanUID, amount)
    }

    async function callContract(contractAddress, signer) {
        let contract = new ethers.Contract(contractAddress, SBC_Abi, signer)

        return await contract.clanStake(clanUID, amount, { gasLimit: 500000 })
    }

    return (
        <Web3ActionsSheet
            states={[{ icon: clanStakeIcon, title: "Stack To Clan" }]}
            getTransaction={getTransaction}
            addTransaction={addTransaction}
            callContract={callContract}
            wallet={wallet}
        />
    )
}