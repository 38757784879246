import React, { Component, useEffect, useState, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Web3ReactProvider, useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import { ethers } from 'ethers'

import { SBC_Abi } from '../AppConfig'
import BackEnd from '../BackEnd'
import { NetworkContext } from './Metamask'

import 'antd/dist/antd.min.css'
import { Space, Spin, notification } from 'antd'

import { Action } from './Action'
import { ProgressGrid } from './ProgressGrid'
import { ActionHelper } from './ActionHelper'
import { Loading } from './Loading'

import claimIcon from '../images/claim_reward_icon.png'
import backToGameIcon from '../images/icon_tower.png'

export function ClaimReward() {
    const { wallet } = useParams()
    const { active, account, library, connector, activate, deactivate } = useWeb3React()
    const [networkState] = useContext(NetworkContext)

    const ClaimReward = BackEnd.TransactionType.ClaimReward

    const [loading, setLoading] = useState(false)
    const [showHelpText, setShowHelpText] = useState(undefined)

    const initialStates = [
        { id: 0, icon: claimIcon, title: 'Claim Rewards', helpText: "Complete Request in MetaMask..." },   
        { id: 1, icon: backToGameIcon, title: 'Get Back to The Game' },
    ]

    const [states, setStates] = useState(initialStates)
    const [currentStateId, setCurrentStateId] = useState(0)

    const currentState = states.find(s => s.id === currentStateId)

    function setNextState() {
        var nextstateId = currentStateId + 1
        setCurrentStateId(nextstateId)
        setLoading(false)
        setShowHelpText(undefined)
    }

    useEffect(() => {
        if (active) {
            console.log("Web3React status: ", active)

            setLoading(true)

            BackEnd.GetTransaction(ClaimReward, account)
                .then(function (response) {
                    //if (response.ok) setShowResults(true)
                    setLoading(false)
                })
                .catch(function (error) {
                    console.log("Error", error.message)
                    setLoading(false)
                });
        }
    }, [networkState])

    useEffect(() => {

        if (account === undefined)
            return

        if (account != wallet) {
            setShowHelpText("Please connect your wallet in Metamask " + wallet)
            return
        }

        setShowHelpText(undefined)

    }, [account])

    async function execute() {
        try {
            setShowHelpText(currentState.helpText)

            const settings = await BackEnd.GetSettings()
            const ContractAddress = settings.contractAddress

            let contract = new ethers.Contract(ContractAddress, SBC_Abi, library.getSigner(account))
            
            var result = await contract.getUserReward(wallet)

            //walletSigner.sendTransaction(txPromise, { gasPrice: ethers.utils.parseUnits('100', 'gwei'), gasLimit: 1000000 });
            //var result = await claimRewardContract.claimReward(account, { gasLimit: 800000 });        
            var transaction = await contract.claimReward(account, { gasLimit: 500000 })

            if (networkState === false) {
                setShowHelpText("Wrong network in Metamask...")
                return
            }

            BackEnd.AddTransaction(ClaimReward, account, transaction.hash, result)

            console.log(transaction)

            setNextState()
        } catch (error) {
            notification.error({
                message: "Error",
                description: error.message,
            }) 
            console.log("claimReward failed:" + error);
            setShowHelpText(undefined)
        }       
    }

  return (
      <div> 
          {(library && account && networkState) && <ProgressGrid states={states} currentStateId={currentStateId} />}

          {(library && account && networkState && !loading && showHelpText === undefined && currentStateId === 0) && (
              <Action Text="Claim Reward" Description="Please confirm the request in metamask" onClick={() => execute()}></Action>
          )}

          {(loading) && <Loading text="Please wait..." />}
          {(showHelpText !== undefined && !loading) && <ActionHelper text={showHelpText} />} 
    </div>
  );
}
