import React, {Component, useEffect, useState, useContext} from 'react';
import {Link, useParams} from 'react-router-dom';
import {Web3ReactProvider, useWeb3React, UnsupportedChainIdError} from '@web3-react/core'

import {ethers, utils} from 'ethers'

import {SBC_Abi_Lootboxes} from '../AppConfig'
import BackEnd from '../BackEnd'
import {NetworkContext} from './Metamask'

import 'antd/dist/antd.min.css'
import {Space, Spin, notification} from 'antd'

import {Action} from './Action'
import {ProgressGrid} from './ProgressGrid'
import {ActionHelper} from './ActionHelper'
import {Loading} from './Loading'

import claimIcon from '../images/claim_reward_icon.png'
import backToGameIcon from '../images/icon_tower.png'

var claimData = null;

export function ClaimBatchLootbox() {
    const {wallet} = useParams()
    const {active, account, library, connector, activate, deactivate} = useWeb3React()
    const [networkState] = useContext(NetworkContext)

    const ClaimBatchLootbox = BackEnd.TransactionType.ClaimBatchLootbox;

    const [loading, setLoading] = useState(false)
    const [showHelpText, setShowHelpText] = useState(undefined)

    const initialStates = [
        {id: 0, icon: claimIcon, title: 'Claim Lootbox', helpText: "Complete Request in MetaMask..."},
        {id: 1, icon: backToGameIcon, title: 'Get Back to The Game'},
    ]

    const [states, setStates] = useState(initialStates)
    const [currentStateId, setCurrentStateId] = useState(0)

    const currentState = states.find(s => s.id === currentStateId)


    function setNextState() {
        var nextstateId = currentStateId + 1
        setCurrentStateId(nextstateId)
        setLoading(false)
        setShowHelpText(undefined)
    }

    useEffect(() => {
        if (active) {
            console.log("Web3React status: ", active)

            setLoading(true)


            BackEnd.GetLootboxClaimProofBatch(account).then(function (response) {
                response.json().then(claimBatchDataResponse => {

                    claimData = claimBatchDataResponse
                    const claimDataJson = JSON.stringify(claimData);

                    BackEnd.GetTransaction(ClaimBatchLootbox, account, encodeURI(claimDataJson))
                        .then(function (response) {

                            setLoading(false)
                        })
                        .catch(function (error) {
                            console.log("Error", error.message)
                            setLoading(false)
                        });
                });


            })
                .catch(function (error) {
                    console.log("Error", error.message)
                    setLoading(false)
                });
        }
    }, [networkState])

    useEffect(() => {

        if (account === undefined)
            return

        if (account != wallet) {
            setShowHelpText("Please connect your wallet in Metamask " + wallet)
            return
        }

        setShowHelpText(undefined)

    }, [account])

    async function execute() {
        try {
            setShowHelpText(currentState.helpText)

            const settings = await BackEnd.GetSettings()
            const ContractLootboxesAddress = settings.contractLootboxesAddress

            let contract = new ethers.Contract(ContractLootboxesAddress, SBC_Abi_Lootboxes, library.getSigner(account))

            const sortedDropIds = claimData.sortedDropIds;
            const proofFlags = claimData.proofFlags;
            const dropData = claimData.dropData;
            const proofArr = claimData.proof;

            console.log(sortedDropIds);
            console.log(proofFlags);
            console.log(dropData);
            console.log(proofArr);

            const claimDataJson = JSON.stringify(claimData);

            try {
                var transaction = await contract.claimBatch(proofArr, proofFlags, dropData, sortedDropIds);

                if (networkState === false) {
                    setShowHelpText("Wrong network in Metamask...")
                    return
                }

                BackEnd.AddTransaction(ClaimBatchLootbox, account, transaction.hash, encodeURI(claimDataJson));

                setNextState()
            }
            catch (e){                
                console.log(e.error.message);
                setShowHelpText(e.error.message);
            }
            
        } catch (error) {
            notification.error({
                message: "Error",
                description: error.message,
            })
            console.log("claimBatchLootbox failed:" + error);
            setShowHelpText(undefined)
        }
    }

    return (
        <div>
            {(library && account && networkState) && <ProgressGrid states={states} currentStateId={currentStateId}/>}

            {(library && account && networkState && !loading && showHelpText === undefined && currentStateId === 0) && (
                <Action Text="Claim Lootboxes" Description="Please confirm the request in metamask"
                        onClick={() => execute()}></Action>
            )}

            {(loading) && <Loading text="Please wait..."/>}
            {(showHelpText !== undefined && !loading) && <ActionHelper text={showHelpText}/>}
        </div>
    );
}
