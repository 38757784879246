import React, { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'

import './Loading.css'

import shordSwordIcon from '../images/short_sword.png'
import longSwordIcon from '../images/long_sword.png'

export function Loading({ text }) {
    return (
        <div class="loading">
            <img src={shordSwordIcon} loading="lazy" id="w-node-ee55f280-a622-dd15-99e7-6c11dd4d81d5-268c36a8" alt="" class="shortsword"></img>
            <img src={longSwordIcon} loading="lazy" id="w-node-_70ed4391-1f65-33fa-5cf8-2ece72817e35-268c36a8" alt="" class="longsword"></img>
            <div class="loadiangtext">{text}</div>
        </div>
    );
}