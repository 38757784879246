import { useParams } from 'react-router-dom';
import { ethers } from 'ethers'
import { SBC_Abi } from '../AppConfig'
import BackEnd from '../BackEnd'

import { Web3ActionsSheet } from './Web3ActionsSheet'

import withdrawIcon from '../images/icon_withdraw.png'

export function ClanWithdrawRequest() {
    const { wallet, clanUID, amount } = useParams()

    async function getTransaction() {
        return BackEnd.GetTransaction(BackEnd.TransactionType.ClanWithdrawRequest, wallet, clanUID, amount)
    }

    async function addTransaction(transactionHash) {
        return BackEnd.AddTransaction(BackEnd.TransactionType.ClanWithdrawRequest, wallet, transactionHash, clanUID, amount)
    }

    async function callContract(contractAddress, signer) {
        let contract = new ethers.Contract(contractAddress, SBC_Abi, signer)

        return await contract.clanWithdrawRequest(clanUID, amount, { gasLimit: 500000 })
    }

    return (
        <Web3ActionsSheet
            states={[{ icon: withdrawIcon, title: "Clan Withdraw Request" }]}
            getTransaction={getTransaction}
            addTransaction={addTransaction}
            callContract={callContract}
            wallet={wallet}
        />
    )
}