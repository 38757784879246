import { Home } from "./components/Home";
import { Login } from "./components/Login";
import { Mint } from "./components/Mint";
import { ClaimReward } from "./components/ClaimReward";
import { Burn } from "./components/Burn";
import { CreateClan } from "./components/CreateClan";
import { JoinClan } from "./components/JoinClan";
import { WithdrawJoinClan } from "./components/WithdrawJoinClan";
import { LeaveClan } from "./components/LeaveClan";
import { AbandonClan } from "./components/AbandonClan";
import { ApproveJoinClan } from "./components/ApproveJoinClan";
import { DismissJoinClan } from "./components/DismissJoinClan";
import { SetClanRole } from "./components/SetClanRole";
import { SetClanName } from "./components/SetClanName";
import { ClanStake } from "./components/ClanStake";
import { ClanWithdraw } from "./components/ClanWithdraw";
import { ClanWithdrawRequest } from "./components/ClanWithdrawRequest";
import { KickFromClan } from "./components/KickFromClan";
import { TransferClan } from "./components/TransferClan";
import {ClaimLootbox} from "./components/ClaimLootbox";
import {ClaimBatchLootbox} from "./components/ClaimBatchLootbox";

const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/login/:loginId',
        element: <Login />
    },
    {
        path: '/mint/:wallet/:loginId',
        element: <Mint />
    },
    {
        path: '/burn/:wallet/:knightId',
        element: <Burn />
    },  
    {
        path: '/claimreward/:wallet',
        element: <ClaimReward />
    },
    {
        path: '/createclan/:wallet/:knightId/:clanName',
        element: <CreateClan />
    },
    {
        path: '/joinclan/:wallet/:knightId/:clanUID',
        element: <JoinClan />
    },
    {
        path: '/withdrawjoinclan/:wallet/:knightId/:clanUID',
        element: <WithdrawJoinClan />
    },
    {
        path: '/leaveclan/:wallet/:knightId/:clanUID',
        element: <LeaveClan />
    },
    {
        path: '/abandonclan/:wallet/:knightId/:clanUID',
        element: <AbandonClan />
    },
    {
        path: '/approveJoinClan/:wallet/:knightId/:clanUID/:callerKnightUID',
        element: <ApproveJoinClan />
    },
    {
        path: '/dismissJoinClan/:wallet/:knightId/:clanUID/:callerKnightUID',
        element: <DismissJoinClan />
    }, 
    {
        path: '/setclanrole/:wallet/:knightId/:clanUID/:role/:callerKnightUID',
        element: <SetClanRole />
    },
    {
        path: '/setclanname/:wallet/:clanUID/:newClanName',
        element: <SetClanName />
    },
    {
        path: '/clanstake/:wallet/:clanUID/:amount',
        element: <ClanStake />
    },
    {
        path: '/clanwithdraw/:wallet/:clanUID/:amount',
        element: <ClanWithdraw />
    },
    {
        path: '/clanwithdrawrequest/:wallet/:clanUID/:amount',
        element: <ClanWithdrawRequest />
    },
    {
        path: '/kickfromclan/:wallet/:clanUID/:knightId/:callerKnightUID',
        element: <KickFromClan />
    },
    {
        path: '/transferClan/:wallet/:knightId/:clanUID/:callerKnightUID',
        element: <TransferClan />
    },
    {
        path: '/claimLootbox/:wallet/',
        element: <ClaimLootbox />
    },
    {
        path: '/claimBatchLootbox/:wallet/',
        element: <ClaimBatchLootbox />
    }
];

export default AppRoutes;
