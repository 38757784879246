import React, { useEffect, useRef } from 'react';
import { useWeb3React } from '@web3-react/core'
import jazzicon from '@metamask/jazzicon'

import './MetamaskHeader.css'

import avatarIcon from '../images/avatar.png'
import walletIcon from '../images/icon_wallet.png'

export function MetamaskHeader()
{
    const { account } = useWeb3React();
    const avatarRef = useRef()

    useEffect(() => {
        const element = avatarRef.current;
        if (element && account) {
            const addr = account.slice(2, 10);
            const seed = parseInt(addr, 16);
            const icon = jazzicon(60, seed); //generates a size 20 icon
            if (element.firstChild) {
                element.removeChild(element.firstChild);
            }
            element.appendChild(icon);
        }
    }, [account, avatarRef]);

    
    return (
        <div>
            { account &&
                <div class="metamaskheader">
                    {/*<img src={avatarIcon} loading="lazy" alt="" className="avatar" />*/}
                    <div ref={avatarRef} className="avatar" />
                    <div class="wallet">
                        <img src={walletIcon} loading="lazy" alt="" class="walletimage" />
                        <div class="walletlabel">{account ? `${account.substring(0, 6)}...${account.substring(account.length - 4)}` : ''}</div>
                    </div>
                </div>}
        </div>
    );
}


