import React, { useEffect, useState, useContext } from 'react';
import { useWeb3React } from '@web3-react/core'
import BackEnd from '../BackEnd'
import { NetworkContext } from './Metamask'

import 'antd/dist/antd.min.css'
import { notification } from 'antd'

import { Action } from './Action'
import { ProgressGrid } from './ProgressGrid'
import { ActionHelper } from './ActionHelper'
import { Loading } from './Loading'

import backToGameIcon from '../images/icon_tower.png'

export function Web3ActionsSheet(props) {
    const { active, account, library } = useWeb3React()
    const [networkState] = useContext(NetworkContext)

    const [loading, setLoading] = useState(false)
    const [showHelpText, setShowHelpText] = useState(undefined)

    const initialStates = [
        { id: 0, icon: props.states[0].icon, title: props.states[0].title, helpText: "Complete Request in MetaMask..."},
        { id: 1, icon: backToGameIcon, title: 'Get Back to The Game'},
    ]

    const [states, setStates] = useState(initialStates)
    const [currentStateId, setCurrentStateId] = useState(0)

    let currentState = states.find(s => s.id === currentStateId)

    function setNextState() {
        var nextstateId = currentStateId + 1
        setCurrentStateId(nextstateId)
        setLoading(false)
        setShowHelpText(undefined)
    }

    useEffect(() => {
        if (active) {
            console.log("Web3React status: ", active)

            setLoading(true)

            props.getTransaction()
                .then(function (response) {
                //if (response.ok) setShowResults(true)
                    setLoading(false)
                })
                .catch(function (error) {
                    console.log("Error", error.message)
                    setLoading(false)
                });
                
        }
    }, [networkState])

    useEffect(() => {
        if (account === undefined)
            return

        if (account != props.wallet) {
            setShowHelpText("Please connect your wallet in Metamask " + props.wallet)
            return
        }

        setShowHelpText(undefined)

    }, [account])

    async function execute() {
        try {
            setShowHelpText(currentState.helpText)

            const settings = await BackEnd.GetSettings()

            var transaction = await props.callContract(settings.contractAddress, library.getSigner(account))

            if (networkState === false) {
                setShowHelpText("Wrong network in Metamask...")
                return
            }  

            await props.addTransaction(transaction.hash);

            console.log(transaction)

            setNextState()

        } catch (error) {
            notification.error({
                message: "Error",
                description: error.message,
            })           
            console.log(currentState.title + " failed:" + error)
            setShowHelpText(undefined)
        }       
    }

    return (
        <div>
            {(library && account && networkState) && <ProgressGrid states={states} currentStateId={currentStateId} />}

            {(library && account && networkState && !loading && showHelpText === undefined && currentStateId === 0) && (
                <Action Text={currentState.title} Description="Please confirm the request in metamask" onClick={() => execute()}></Action>
            )}

            {(loading) && <Loading text="Please wait..." />}
            {(showHelpText !== undefined && !loading) && <ActionHelper text={showHelpText} />} 
        </div>
    )
}


