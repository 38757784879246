import { useParams } from 'react-router-dom';
import { ethers } from 'ethers'
import { SBC_Abi } from '../AppConfig'
import BackEnd from '../BackEnd'

import { Web3ActionsSheet } from './Web3ActionsSheet'

import changeClanNameIcon from '../images/icon_clan.png'

export function SetClanName() {
    const { wallet, clanUID, newClanName} = useParams()

    async function getTransaction() {
        return BackEnd.GetTransaction(BackEnd.TransactionType.SetClanName, wallet, clanUID, newClanName)
    }

    async function addTransaction(transactionHash) {
        return BackEnd.AddTransaction(BackEnd.TransactionType.SetClanName, wallet, transactionHash, clanUID, newClanName)
    }

    async function callContract(contractAddress, signer) {
        let contract = new ethers.Contract(contractAddress, SBC_Abi, signer)
        console.log(clanUID);
        return await contract.setClanName(clanUID, newClanName, { gasLimit: 500000 })
    }

    return (
        <Web3ActionsSheet
            states={[{ icon: changeClanNameIcon, title: "Change Clan Name" }]}
            getTransaction={getTransaction}
            addTransaction={addTransaction}
            callContract={callContract}
            wallet={wallet}
        />
    )
}