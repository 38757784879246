import React, { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'

import './ProgressGrid.css'

import rhombusIcon from '../images/rhombus.png'
import checkMarkIcon from '../images/check_mark.png'
import currentStateIcon from '../images/frame.png'

import stateBgActiveIcon from '../images/icon_rhomb_active.png'
import stateBgInactiveIcon from '../images/icon_rhomb_inactive.png'
import stateBgNextIcon from '../images/icon_rhomb_violet.png'

export function ProgressGrid( { states, currentStateId } ) {
    return (
        <div class="progressgrid">
            {states.map(state => (
                <>
                    {(state.id === currentStateId &&
                        <img src={stateBgActiveIcon} alt="" loading="lazy" width="203" className="image-bg"
                            style={{ gridColumnStart: (state.id + 1) + state.id, gridColumnEnd: (state.id + 1) + state.id, gridRowStart: 1, gridRowEnd: 2 }}></img>
                    )}

                    {(state.id > currentStateId &&
                        <img src={stateBgNextIcon} alt="" loading="lazy" width="203" className="image-bg"
                            style={{ gridColumnStart: (state.id + 1) + state.id, gridColumnEnd: (state.id + 1) + state.id, gridRowStart: 1, gridRowEnd: 2 }}></img>
                    )}

                    {(state.id < currentStateId &&
                        <img src={stateBgInactiveIcon} alt="" loading="lazy" width="203" className="image-bg"
                            style={{ gridColumnStart: (state.id + 1) + state.id, gridColumnEnd: (state.id + 1) + state.id, gridRowStart: 1, gridRowEnd: 2 }}></img>
                    )}

                    <img src={state.icon} alt="" loading="lazy" className="image-progress-icon"
                        style={{ gridColumnStart: (state.id + 1) + state.id, gridColumnEnd: (state.id + 1) + state.id, gridRowStart: 1, gridRowEnd: 2 }}></img> 

                    {(state.id === currentStateId &&
                        <img src={currentStateIcon} alt="" loading="lazy" className="image-progress-mark"
                            style={{ gridColumnStart: (state.id + 1) + state.id, gridColumnEnd: (state.id + 1) + state.id, gridRowStart: 1, gridRowEnd: 2 }}></img>)}

                    {(state.id < currentStateId &&
                        <img src={checkMarkIcon} alt="" loading="lazy" className="checkmark"
                            style={{ gridColumnStart: (state.id + 1) + state.id, gridColumnEnd: (state.id + 1) + state.id, gridRowStart: 1, gridRowEnd: 2 }}></img>)}

                    <div class="text-block-progress"
                        style={{ gridColumnStart: (state.id + 1) + state.id, gridColumnEnd: (state.id + 1) + state.id, gridRowStart: 2, gridRowEnd: 2 }}>{state.title}</div>  
                 
                    {(state.id + 1 < states.length &&
                        <div class="dots-progress" style={{ gridColumnStart: (state.id + 2) + state.id, gridColumnEnd: (state.id + 2) + state.id, gridRowStart: 1, gridRowEnd: 2 }}>
                            <img src={rhombusIcon} loading="lazy" alt="" className="image-dot"></img>
                            <img src={rhombusIcon} loading="lazy" alt="" className="image-dot"></img>
                            <img src={rhombusIcon} loading="lazy" alt="" className="image-dot"></img>
                        </div>)}
                </>                
            ))}
        </div>
    );
}

