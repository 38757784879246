export const ContractAbi = [
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "caller",
                "type": "address"
            }
        ],
        "name": "AccessControlModifiers_CallerIsNotAdmin",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "reward",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "user",
                "type": "address"
            }
        ],
        "name": "ClaimAmountExceedsReward",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            },
            {
                "internalType": "enum ClanRole",
                "name": "newRole",
                "type": "uint8"
            },
            {
                "internalType": "uint256",
                "name": "callerId",
                "type": "uint256"
            }
        ],
        "name": "ClanFacet_CantAssignNewRoleToThisCharacter",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            }
        ],
        "name": "ClanFacet_CantJoinAlreadyInClan",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "kickerId",
                "type": "uint256"
            }
        ],
        "name": "ClanFacet_CantJoinOtherClanWhileBeingAClanLeader",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "kickerId",
                "type": "uint256"
            }
        ],
        "name": "ClanFacet_CantKickThisMember",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "callerId",
                "type": "uint256"
            }
        ],
        "name": "ClanFacet_InsufficientRolePriveleges",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "stakeAvalible",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "withdrawAmount",
                "type": "uint256"
            }
        ],
        "name": "ClanFacet_InsufficientStake",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            }
        ],
        "name": "ClanFacet_NoJoinProposal",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            }
        ],
        "name": "ClanFacet_NoProposalOrNotClanLeader",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            }
        ],
        "name": "ClanModifiers_AboveMaxMembers",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            }
        ],
        "name": "ClanModifiers_ClanDoesntExist",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "clanName",
                "type": "string"
            }
        ],
        "name": "ClanModifiers_ClanNameTaken",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "clanName",
                "type": "string"
            }
        ],
        "name": "ClanModifiers_ClanNameWrongLength",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            }
        ],
        "name": "ClanModifiers_ClanOwnersCantCallThis",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            }
        ],
        "name": "ClanModifiers_JoinProposalToSomeClanExists",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            }
        ],
        "name": "ClanModifiers_KickingMembersOnCooldownForThisKnight",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            }
        ],
        "name": "ClanModifiers_KnightInSomeClan",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            }
        ],
        "name": "ClanModifiers_KnightIsClanLeader",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            }
        ],
        "name": "ClanModifiers_KnightIsNotClanLeader",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            }
        ],
        "name": "ClanModifiers_KnightNotInThisClan",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            }
        ],
        "name": "ClanModifiers_KnightOnClanActivityCooldown",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            }
        ],
        "name": "ClanModifiers_NotClanOwner",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "user",
                "type": "address"
            }
        ],
        "name": "ClanModifiers_UserOnWithdrawalCooldown",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "user",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "withdrawalAmount",
                "type": "uint256"
            }
        ],
        "name": "ClanModifiers_WithdrawalAbovePending",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "user",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "withdrawalAmount",
                "type": "uint256"
            }
        ],
        "name": "ClanModifiers_WithdrawalAmountAboveStake",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "itemId",
                "type": "uint256"
            }
        ],
        "name": "ItemsModifiers_DontOwnThisItem",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            }
        ],
        "name": "KnightFacet_AbandonLeaderRoleBeforeBurning",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            }
        ],
        "name": "KnightFacet_CantAppointYourselfAsHeir",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "heirId",
                "type": "uint256"
            }
        ],
        "name": "KnightFacet_HeirIsNotInTheSameClan",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "heirId",
                "type": "uint256"
            }
        ],
        "name": "KnightFacet_HeirIsNotKnight",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "avalible",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "required",
                "type": "uint256"
            }
        ],
        "name": "KnightFacet_InsufficientFunds",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            }
        ],
        "name": "KnightModifiers_KnightInSomeClan",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            }
        ],
        "name": "KnightModifiers_KnightNotInAnyClan",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "wrongClanId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "correctClanId",
                "type": "uint256"
            }
        ],
        "name": "KnightModifiers_KnightNotInClan",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "wrongId",
                "type": "uint256"
            }
        ],
        "name": "KnightModifiers_WrongKnightId",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "user",
                "type": "address"
            }
        ],
        "name": "NoRewardToClaim",
        "type": "error"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "newAdmin",
                "type": "address"
            }
        ],
        "name": "AdminAdded",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "oldAdmin",
                "type": "address"
            }
        ],
        "name": "AdminRemoved",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "account",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "operator",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "bool",
                "name": "approved",
                "type": "bool"
            }
        ],
        "name": "ApprovalForAll",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            }
        ],
        "name": "ClanAbandoned",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            }
        ],
        "name": "ClanCreated",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "callerId",
                "type": "uint256"
            }
        ],
        "name": "ClanJoinProposalAccepted",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            }
        ],
        "name": "ClanJoinProposalDismissed",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            }
        ],
        "name": "ClanJoinProposalSent",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            }
        ],
        "name": "ClanJoinProposalWithdrawn",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            }
        ],
        "name": "ClanKnightJoined",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "callerId",
                "type": "uint256"
            }
        ],
        "name": "ClanKnightKicked",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            }
        ],
        "name": "ClanKnightLeft",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            }
        ],
        "name": "ClanKnightQuit",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "newLevel",
                "type": "uint256"
            }
        ],
        "name": "ClanLeveledDown",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "newLevel",
                "type": "uint256"
            }
        ],
        "name": "ClanLeveledUp",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "uint256[]",
                "name": "levelThresholds",
                "type": "uint256[]"
            },
            {
                "indexed": false,
                "internalType": "uint256[]",
                "name": "maxMembersPerLevel",
                "type": "uint256[]"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "clanActivityCooldownConst",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "clanKickCoolDownConst",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "clanStakeWithdrawCooldownConst",
                "type": "uint256"
            }
        ],
        "name": "ClanNewConfig",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "newLevel",
                "type": "uint256"
            }
        ],
        "name": "ClanNewLevel",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "string",
                "name": "newClanName",
                "type": "string"
            }
        ],
        "name": "ClanNewName",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "enum ClanRole",
                "name": "newRole",
                "type": "uint8"
            }
        ],
        "name": "ClanNewRole",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "user",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "clanStakeTotal",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "walletStakeTotal",
                "type": "uint256"
            }
        ],
        "name": "ClanStakeAdded",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "user",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "cooldownEnd",
                "type": "uint256"
            }
        ],
        "name": "ClanStakeWithdrawRequest",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "user",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "clanStakeTotal",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "walletStakeTotal",
                "type": "uint256"
            }
        ],
        "name": "ClanStakeWithdrawn",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "components": [
                    {
                        "internalType": "address",
                        "name": "facetAddress",
                        "type": "address"
                    },
                    {
                        "internalType": "enum IDiamondCut.FacetCutAction",
                        "name": "action",
                        "type": "uint8"
                    },
                    {
                        "internalType": "bytes4[]",
                        "name": "functionSelectors",
                        "type": "bytes4[]"
                    }
                ],
                "indexed": false,
                "internalType": "struct IDiamondCut.FacetCut[]",
                "name": "_diamondCut",
                "type": "tuple[]"
            },
            {
                "indexed": false,
                "internalType": "address",
                "name": "_init",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "bytes",
                "name": "_calldata",
                "type": "bytes"
            }
        ],
        "name": "DiamondCut",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "newImplementation",
                "type": "address"
            }
        ],
        "name": "DummyUpgraded",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "address",
                "name": "wallet",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "enum Pool",
                "name": "p",
                "type": "uint8"
            },
            {
                "indexed": false,
                "internalType": "enum Coin",
                "name": "c",
                "type": "uint8"
            }
        ],
        "name": "KnightBurned",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "address",
                "name": "wallet",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "enum Pool",
                "name": "p",
                "type": "uint8"
            },
            {
                "indexed": false,
                "internalType": "enum Coin",
                "name": "c",
                "type": "uint8"
            }
        ],
        "name": "KnightMinted",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "previousOwner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "address",
                "name": "user",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "reward",
                "type": "uint256"
            }
        ],
        "name": "SiegeNewWinner",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "user",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "SiegeRewardClaimed",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "operator",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256[]",
                "name": "ids",
                "type": "uint256[]"
            },
            {
                "indexed": false,
                "internalType": "uint256[]",
                "name": "values",
                "type": "uint256[]"
            }
        ],
        "name": "TransferBatch",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "operator",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "id",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "TransferSingle",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "string",
                "name": "value",
                "type": "string"
            },
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "URI",
        "type": "event"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "ownerId",
                "type": "uint256"
            }
        ],
        "name": "abandonClan",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "id",
                "type": "uint256"
            }
        ],
        "name": "accountsByToken",
        "outputs": [
            {
                "internalType": "address[]",
                "name": "",
                "type": "address[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newAdmin",
                "type": "address"
            }
        ],
        "name": "addAdmin",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "callerId",
                "type": "uint256"
            }
        ],
        "name": "approveJoinClan",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "id",
                "type": "uint256"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address[]",
                "name": "accounts",
                "type": "address[]"
            },
            {
                "internalType": "uint256[]",
                "name": "ids",
                "type": "uint256[]"
            }
        ],
        "name": "balanceOfBatch",
        "outputs": [
            {
                "internalType": "uint256[]",
                "name": "",
                "type": "uint256[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "heirId",
                "type": "uint256"
            }
        ],
        "name": "burnKnight",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "user",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "claimSiegeReward",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "clanStake",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "clanWithdraw",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "clanWithdrawRequest",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            },
            {
                "internalType": "string",
                "name": "clanName",
                "type": "string"
            }
        ],
        "name": "createClan",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "enum Pool",
                "name": "pool",
                "type": "uint8"
            },
            {
                "internalType": "enum Coin",
                "name": "coin",
                "type": "uint8"
            }
        ],
        "name": "debugDisablePoolCoinMinting",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "enum Pool",
                "name": "pool",
                "type": "uint8"
            },
            {
                "internalType": "enum Coin",
                "name": "coin",
                "type": "uint8"
            }
        ],
        "name": "debugEnablePoolCoinMinting",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "enum Coin",
                "name": "coin",
                "type": "uint8"
            },
            {
                "internalType": "address",
                "name": "newAddress",
                "type": "address"
            }
        ],
        "name": "debugSetACoinAddress",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "baseURI",
                "type": "string"
            }
        ],
        "name": "debugSetBaseURI",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "enum Coin",
                "name": "coin",
                "type": "uint8"
            },
            {
                "internalType": "address",
                "name": "newAddress",
                "type": "address"
            }
        ],
        "name": "debugSetCoinAddress",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "enum Coin",
                "name": "coin",
                "type": "uint8"
            },
            {
                "internalType": "uint256",
                "name": "newPrice",
                "type": "uint256"
            }
        ],
        "name": "debugSetKnightPrice",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256[]",
                "name": "newThresholds",
                "type": "uint256[]"
            }
        ],
        "name": "debugSetLevelThresholds",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            },
            {
                "internalType": "string",
                "name": "tokenURI",
                "type": "string"
            }
        ],
        "name": "debugSetTokenURI",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "user",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "newCooldownEnd",
                "type": "uint256"
            }
        ],
        "name": "debugSetWithdrawalCooldown",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "components": [
                    {
                        "internalType": "address",
                        "name": "facetAddress",
                        "type": "address"
                    },
                    {
                        "internalType": "enum IDiamondCut.FacetCutAction",
                        "name": "action",
                        "type": "uint8"
                    },
                    {
                        "internalType": "bytes4[]",
                        "name": "functionSelectors",
                        "type": "bytes4[]"
                    }
                ],
                "internalType": "struct IDiamondCut.FacetCut[]",
                "name": "_diamondCut",
                "type": "tuple[]"
            },
            {
                "internalType": "address",
                "name": "_init",
                "type": "address"
            },
            {
                "internalType": "bytes",
                "name": "_calldata",
                "type": "bytes"
            }
        ],
        "name": "diamondCut",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "callerId",
                "type": "uint256"
            }
        ],
        "name": "dismissJoinClan",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes4",
                "name": "_functionSelector",
                "type": "bytes4"
            }
        ],
        "name": "facetAddress",
        "outputs": [
            {
                "internalType": "address",
                "name": "facetAddress_",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "facetAddresses",
        "outputs": [
            {
                "internalType": "address[]",
                "name": "facetAddresses_",
                "type": "address[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_facet",
                "type": "address"
            }
        ],
        "name": "facetFunctionSelectors",
        "outputs": [
            {
                "internalType": "bytes4[]",
                "name": "_facetFunctionSelectors",
                "type": "bytes4[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "facets",
        "outputs": [
            {
                "components": [
                    {
                        "internalType": "address",
                        "name": "facetAddress",
                        "type": "address"
                    },
                    {
                        "internalType": "bytes4[]",
                        "name": "functionSelectors",
                        "type": "bytes4[]"
                    }
                ],
                "internalType": "struct IDiamondLoupe.Facet[]",
                "name": "facets_",
                "type": "tuple[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getClanConfig",
        "outputs": [
            {
                "internalType": "uint256[]",
                "name": "",
                "type": "uint256[]"
            },
            {
                "internalType": "uint256[]",
                "name": "",
                "type": "uint256[]"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            }
        ],
        "name": "getClanInfo",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            }
        ],
        "name": "getClanJoinProposal",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            }
        ],
        "name": "getClanKnightInfo",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            },
            {
                "internalType": "enum ClanRole",
                "name": "",
                "type": "uint8"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            }
        ],
        "name": "getClanLeader",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            }
        ],
        "name": "getClanLevel",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "level",
                "type": "uint256"
            }
        ],
        "name": "getClanLevelThreshold",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getClanMaxLevel",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            }
        ],
        "name": "getClanName",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            }
        ],
        "name": "getClanRole",
        "outputs": [
            {
                "internalType": "enum ClanRole",
                "name": "",
                "type": "uint8"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            }
        ],
        "name": "getClanStake",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            }
        ],
        "name": "getClanTotalMembers",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "user",
                "type": "address"
            }
        ],
        "name": "getClanUserInfo",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getDummyImplementation",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            }
        ],
        "name": "getKnightClan",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            }
        ],
        "name": "getKnightCoin",
        "outputs": [
            {
                "internalType": "enum Coin",
                "name": "",
                "type": "uint8"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            }
        ],
        "name": "getKnightInfo",
        "outputs": [
            {
                "components": [
                    {
                        "internalType": "enum Pool",
                        "name": "pool",
                        "type": "uint8"
                    },
                    {
                        "internalType": "enum Coin",
                        "name": "coin",
                        "type": "uint8"
                    },
                    {
                        "internalType": "address",
                        "name": "owner",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "inClan",
                        "type": "uint256"
                    }
                ],
                "internalType": "struct Knight",
                "name": "",
                "type": "tuple"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            }
        ],
        "name": "getKnightOwner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            }
        ],
        "name": "getKnightPool",
        "outputs": [
            {
                "internalType": "enum Pool",
                "name": "",
                "type": "uint8"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "enum Coin",
                "name": "coin",
                "type": "uint8"
            }
        ],
        "name": "getKnightPrice",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "enum Pool",
                "name": "pool",
                "type": "uint8"
            },
            {
                "internalType": "enum Coin",
                "name": "coin",
                "type": "uint8"
            }
        ],
        "name": "getKnightsBurned",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "enum Coin",
                "name": "coin",
                "type": "uint8"
            }
        ],
        "name": "getKnightsBurnedOfCoin",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "enum Pool",
                "name": "pool",
                "type": "uint8"
            }
        ],
        "name": "getKnightsBurnedOfPool",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "knightsBurnedTotal",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getKnightsBurnedTotal",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "enum Pool",
                "name": "pool",
                "type": "uint8"
            },
            {
                "internalType": "enum Coin",
                "name": "coin",
                "type": "uint8"
            }
        ],
        "name": "getKnightsMinted",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "enum Coin",
                "name": "coin",
                "type": "uint8"
            }
        ],
        "name": "getKnightsMintedOfCoin",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "enum Pool",
                "name": "pool",
                "type": "uint8"
            }
        ],
        "name": "getKnightsMintedOfPool",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "knightsMintedTotal",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getKnightsMintedTotal",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "enum Pool",
                "name": "p",
                "type": "uint8"
            },
            {
                "internalType": "enum Coin",
                "name": "c",
                "type": "uint8"
            }
        ],
        "name": "getPoolAndCoinCompatibility",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "user",
                "type": "address"
            }
        ],
        "name": "getSiegeReward",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getSiegeRewardTotal",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getSiegeWinnerAddress",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getSiegeWinnerClanId",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getSiegeWinnerInfo",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getSiegeWinnerKnightId",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getSiegeYield",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "user",
                "type": "address"
            }
        ],
        "name": "getStakeOf",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getTotalKnightSupply",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getYieldTotal",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "operator",
                "type": "address"
            }
        ],
        "name": "isApprovedForAll",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            }
        ],
        "name": "joinClan",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "callerId",
                "type": "uint256"
            }
        ],
        "name": "kickFromClan",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            }
        ],
        "name": "leaveClan",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "enum Pool",
                "name": "p",
                "type": "uint8"
            },
            {
                "internalType": "enum Coin",
                "name": "c",
                "type": "uint8"
            }
        ],
        "name": "mintKnight",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "owner_",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "oldAdmin",
                "type": "address"
            }
        ],
        "name": "removeAdmin",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256[]",
                "name": "ids",
                "type": "uint256[]"
            },
            {
                "internalType": "uint256[]",
                "name": "amounts",
                "type": "uint256[]"
            },
            {
                "internalType": "bytes",
                "name": "data",
                "type": "bytes"
            }
        ],
        "name": "safeBatchTransferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "id",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            },
            {
                "internalType": "bytes",
                "name": "data",
                "type": "bytes"
            }
        ],
        "name": "safeTransferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "operator",
                "type": "address"
            },
            {
                "internalType": "bool",
                "name": "status",
                "type": "bool"
            }
        ],
        "name": "setApprovalForAll",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            },
            {
                "internalType": "string",
                "name": "newClanName",
                "type": "string"
            }
        ],
        "name": "setClanName",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            },
            {
                "internalType": "enum ClanRole",
                "name": "newRole",
                "type": "uint8"
            },
            {
                "internalType": "uint256",
                "name": "callerId",
                "type": "uint256"
            }
        ],
        "name": "setClanRole",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newImplementation",
                "type": "address"
            }
        ],
        "name": "setDummyImplementation",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "knigthId",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "user",
                "type": "address"
            }
        ],
        "name": "setSiegeWinner",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes4",
                "name": "interfaceId",
                "type": "bytes4"
            }
        ],
        "name": "supportsInterface",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "tokensByAccount",
        "outputs": [
            {
                "internalType": "uint256[]",
                "name": "",
                "type": "uint256[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "id",
                "type": "uint256"
            }
        ],
        "name": "totalHolders",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "id",
                "type": "uint256"
            }
        ],
        "name": "totalSupply",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_newOwner",
                "type": "address"
            }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "uri",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "knightId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "clanId",
                "type": "uint256"
            }
        ],
        "name": "withdrawJoinClan",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
];

export const USDC_Abi =
[
    "function name() public view returns (string)",
    "function symbol() public view returns (string)",
    "function decimals() public view returns (uint8)",

    "function approve(address, uint256)",
    "function mint(uint256)",
    "function balanceOf(address) public view returns (uint256)",
    "function allowance(address, address) public view returns (uint256)",
]


//export const Temp_Abi =
//[
//        "function createClan(uint256,string)",
//        "function joinClan(uint256,uint256)",
//        "function withdrawJoinClan(uint256,uint256)",        
//        "function leaveClan(uint256,uint256)",    
//        "function abandonClan(uint256,uint256)",        //abandonClan(uint256 clanId, uint256 ownerId)
//        "function approveJoinClan(uint256, uint256, uint256)", //function approveJoinClan(uint256 knightId, uint256 clanId, uint256 callerId)
//        "function dismissJoinClan(uint256, uint256, uint256)", //function dismissJoinClan(uint256 knightId, uint256 clanId, uint256 callerId)
//        "function setClanRole(uint256, uint256, uint8, uint256)" //function dismissJoinClan(uint256 clanId, uint256 knightId, ClanRole role, uint256 callerId)
//]

export const SBC_Abi = require('./abi.json')
export const SBC_Abi_Lootboxes = require('./abi-lootboxes.json')


